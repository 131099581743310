import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { IPhone } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>
  The default mode for the GPS in Trailguide is to show you the position on the
  map once if you click on the location icon. It will not follow you around
  while you move.
    </p>
    <p className="my-4">
  If you want to use Trailguide for continuous navigation, you can also do so
  without any problems.
    </p>
    <p><a parentName="p" {...{
        "href": "/howto/install"
      }}>{`How do I install Trailguide as an App?`}</a></p>
    <p><a parentName="p" {...{
        "href": "/howto/gps-on-iphone"
      }}>{`How do I get the GPS working on iPhone?`}</a></p>
    <br />
    <br />
    <div className="flex flex-wrap justify-left">
  <IPhone alt="Open trailguide app settings" img="/howto/trailguide-iphone-navigation-1.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="1) Open the trailguide app settings dialog" mdxType="IPhone" />
  <IPhone alt="Privacy menu" img="/howto/trailguide-iphone-navigation-2.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="2) Turn on navigation mode" mdxType="IPhone" />
  <IPhone alt="Toggle navigation mode" img="/howto/trailguide-iphone-navigation-3.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="3) You can also toggle between navigation and location mode by pressing and holding the location icon for more than one second" mdxType="IPhone" />
  <IPhone alt="Voila! Trailguide will now always follow your location" img="/website/trailguide-iphone-breadcrumbs.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="4) Voila! Trailguide will now always follow your location" mdxType="IPhone" />
    </div>
    <div className="mt-12 flex flex-wrap justify-left">
  <p>
    On Android the screen will normally not shut down while Trailguide is in
    navigation mode, but this might happen on the iPhone. In this case, you can
    set the screen Auto-Lock to "never".
  </p>
  <IPhone alt="Open the settings app on your iPhone" img="/howto/trailguide-iphone-navigation-5.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="5) Open the settings app on your iPhone" mdxType="IPhone" />
  <IPhone alt="Set Auto-Lock to never" img="/howto/trailguide-iphone-navigation-6.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="6) Set Auto-Lock to never" mdxType="IPhone" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      